<template>
  <div class="test-check">
    <div class="inner">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/manager/test/list' }">考试阅卷</el-breadcrumb-item>
        <el-breadcrumb-item>考试</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="t-header">
        <span class="t-name">{{ testPaper.examName }}</span>
        <span>
          考生姓名：
          <font>{{ testPaper.studentName }}</font>
        </span>
        <span>
          答题时间：
          <font>{{ testPaper.studentExamDuration }}分钟</font>
        </span>
      </div>
      <div class="t-body">
        <div class="t-aside">
          <div class="t-text">答题卡</div>
          <div class="t-detail" v-show="!showQA">
            <div
              class="t-list"
              v-for="(qTypeItem, qTypeIndex) in testPaper.questionTypes"
              :key="qTypeIndex"
            >
              <div class="t-type">
                {{ qTypeIndex + 1 }}.{{ typeConfig[qTypeItem.questionType] }}
                <font>共{{ qTypeItem.questionNum }}题，合计{{ qTypeItem.totalScore }}分</font>
              </div>
              <div class="t-wrap" >
                <div
				v-for="(item, questionIndex) in qTypeItem.correctFlagList"
                  :class="[
                    item == 'y' ? 't-right-item' : '',
                    item == 'n' ? 't-wrong-item' : '',
                    item == '' ? 't-item' : '',
                    item == null ? 't-item' : ''
                  ]"
                  @click="changeQues(qTypeItem.questionType)"
                >
                  {{ questionIndex + 1 }}
                </div>
              </div>
            </div>
          </div>
          <div class="t-detail" v-show="showQA">
            <div
              class="t-list"
              v-for="(qTypeItem, qTypeIndex) in testPaper.questionTypes"
              :key="qTypeIndex"
              v-if="qTypeItem.questionType === 6"
            >
              <div class="t-type">
                {{ qTypeIndex + 1 }}.{{ typeConfig[qTypeItem.questionType] }}
                <font>共{{ qTypeItem.questionNum }}题，合计{{ qTypeItem.totalScore }}分</font>
              </div>
              <div class="t-wrap" v-for="(item, questionIndex) in qTypeItem.correctFlagList">
                <div
                  :class="[
                    item == 'y' ? 't-right-item' : '',
                    item == 'n' ? 't-wrong-item' : '',
                    item == '' ? 't-item' : '',
                    item == null ? 't-item' : ''
                  ]"
                >
                  {{ questionIndex + 1 }}
                </div>
              </div>
            </div>
          </div>
          <div class="t-tips">
            <div class="t-item">
              <span class="t-green"></span>
              <span class="t-right">正确</span>
            </div>
            <div class="t-item">
              <span class="t-red"></span>
              <span class="t-wrong">错误</span>
            </div>
          </div>
          <div class="t-buttons">
            <div class="wrap">
              <el-button plain @click="changeTestPaper('up')">上一个学生</el-button>
              <el-button plain @click="changeTestPaper('down')">下一个学生</el-button>
            </div>
            <el-button plain @click="showQA = false">显示全部</el-button>
            <el-button plain @click="showQA = true">仅显示需我阅卷</el-button>
            <el-button plain @click="finishCheck">结束本学生的判卷</el-button>
          </div>
        </div>
        <div class="t-wrap" v-show="showQA">
          <div
            class="t-question"
            v-for="(questionItem, questionIndex) in testPaper.questionTypes"
            :key="questionIndex"
            v-if="questionItem.questionType === 6"
          >
            <div class="t-type">
              {{ typeConfig[questionItem.questionType] }}
              <font>共{{ questionItem.questionNum }}题，合计{{ questionItem.totalScore }}分</font>
            </div>
            <div
              class="t-item"
              v-for="(question, questionidx) in questionItem.questions"
              :key="questionidx"
			  style="margin-bottom: 20px;"
            >
              <div class="t-title"><font>{{ questionidx + 1 }}.</font><font style="display: inline-block;" v-html="question.questionDesc"></font></div>
              <div class="t-questions" v-show="question.questionType == 6"></div>
              <div class="t-tips">
                <span>
                  考生答案:
                  <span v-for="(item, index) in question.studentAnswer" :key="index">
                    {{ item }}
                  </span>
                </span>
                <br />
                <span>
                  正确答案:
                  <span v-for="(item, index) in question.correctAnswers" :key="index">
                    {{ item }}
                  </span>
                </span>
                <br />
                <span>
                  解释说明:
                  <span  style="display: inline-block;" v-html="question.questionAnls"></span>
                </span>
              </div>
              <div class="t-btns">
                <el-button class="t-0" @click="setZero(question)">0分</el-button>
                <el-button class="t-100" @click="set100(question, questionItem)">满分</el-button>
                <el-input
                  type="number"
                  min="1"
                  onKeypress="
                    return (
                      /[\d]/.test(String.fromCharCode(event.keyCode || event.which)) ||
                      event.which === 8
                    );
                  "
                  @input="onScore(question, questionItem)"
                  class="scoreInput"
                  placeholder="得分"
                  v-model.numer="question.studentScore"
                />
                <el-button
                  class="t-confirm"
                  type="primary"
                  @click="
                    submitScore(questionidx, questionItem.correctFlagList, question.studentScore)
                  "
                >
                  确定
                </el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="t-wrap" v-show="!showQA">
          <div
            class="t-question"
            v-for="(questionItem, questionIndex) in testPaper.questionTypes"
            :key="questionIndex"
            v-if="questionItem.questionType === currentPage"
          >
            <div class="t-type">
              {{ typeConfig[questionItem.questionType] }}
              <font>共{{ questionItem.questionNum }}题，合计{{ questionItem.totalScore }}分</font>
            </div>
            <div
              class="t-item"
              v-for="(question, questionidx) in questionItem.questions"
              :key="questionidx"
			  style="margin-top:20px;"
            >
              <div class="t-title">{{ questionidx + 1 }}.<font style="display: inline-block;" v-html="question.questionDesc"></font></div>
              <div
                class="t-answer"
                v-show="question.questionType == 1 || question.questionType == 4"
              >
                <div
                  class="t-answer-item"
                  v-for="(optionItem, optionIndex) in question.options"
                  :key="optionIndex"
                >
                  <el-radio-group>
                    <el-radio class="t-radio" :label="1">
                      <span class="t-option">{{ optionItem.optionCode }}</span>
                      {{ optionItem.optionDesc }}
                    </el-radio>
                  </el-radio-group>
                </div>
              </div>
              <div
                class="t-answer"
                v-show="question.questionType == 2 || question.questionType == 3"
              >
                <div
                  class="t-answer-item"
                  v-for="(optionItem, optionIndex) in question.options"
                  :key="optionItem.optionId"
                >
                  <el-checkbox class="t-radio">
                    <span class="t-option">{{ optionItem.optionCode }}</span>
                    {{ optionItem.optionDesc }}
                  </el-checkbox>
                </div>
              </div>
              <div
                class="t-questions"
                v-show="question.questionType == 5 || question.questionType == 6"
              ></div>
              <div class="t-tips">
                <span>
                  考生答案:
                  <span v-for="(item, index) in question.studentAnswer" :key="index">
                    {{ item }}
                  </span>
                </span>
                <br />
                <span>
                  正确答案:
                  <span v-for="(item, index) in question.correctAnswers" :key="index">
                    {{ item }}
                  </span>
                </span>
                <br />
                <span>
                  解释说明:
                  <span style="display: inline-block;" v-html=" question.questionAnls"></span>
                </span>
              </div>
              <div
                class="t-btns"
                v-if="questionItem.questionType === 5 || questionItem.questionType === 6"
              >
                <el-button class="t-0" @click="setZero(question)">0分</el-button>
                <el-button class="t-100" @click="set100(question, questionItem)">满分</el-button>
                <el-input
                  type="number"
                  min="1"
                  onKeypress="
                    return (
                      /[\d]/.test(String.fromCharCode(event.keyCode || event.which)) ||
                      event.which === 8
                    );
                  "
                  @input="onScore(question, questionItem)"
                  class="scoreInput"
                  placeholder="得分"
                  v-model.numer="question.studentScore"
                />
                <el-button
                  class="t-confirm"
                  type="primary"
                  @click="
                    submitScore(questionidx, questionItem.correctFlagList, question.studentScore)
                  "
                >
                  确定
                </el-button>
              </div>
			  <!--
              <div class="t-btns" v-else>
                <el-button
                  class="t-right"
                  @click="right(question, questionItem, questionidx, questionItem.correctFlagList)"
                >
                  正确
                </el-button>
                <el-button
                  class="t-wrong"
                  @click="wrong(question, questionidx, questionItem.correctFlagList)"
                >
                  错误
                </el-button>
              </div>
			  -->
            </div>
          </div>
        </div>
      </div>
      <div class="t-page-wrap" v-show="!showQA">
        <div class="t-page">
          <span :class="havePre ? '' : 't-limit'" @click="prePage">上一页</span>
          <span :class="haveNext ? '' : 't-limit'" @click="nextPage">下一页</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {},
  data() {
    return {
      showQA: false,
      score: '',
      testPaper: {},
      input: '',
      content: '',
      showResult: false,
      questionNum: 0,
      questionList: [],
      haveNext: true,
      havePre: true,
      examId: this.$route.query.examId,
      studentId: this.$route.query.studentId,
      currentPage: 1,
      totalQuestionTypeNum: 0,
      typeConfig: {
        1: '单选题',
        2: '多选题',
        3: '不定项选择题',
        4: '判断题',
        5: '填空题',
        6: '问答题'
      },
      currentScore: '',
      scoreFlag: '',
      curQuestionFullScore: '',
      studentTestId: ''
    };
  },
  computed: {},
  created() {
    this.gettestPaper();
  },
  mounted() {},
  watch: {},
  methods: {
    // 获取考试卷子
    gettestPaper() {
      this.post(
        `/biz/teacher/qb/studentExamDetail`,
        { examId: this.examId, studentId: this.studentId },
        e => {
          if (e.code == 200) {
            // 添加分数字段
            e.data.questionTypes.forEach(questionItem => {
              questionItem.eachScore = questionItem.totalScore / questionItem.questionNum;
              questionItem.questions.forEach(question => {
                question.studentScore = '';
              });
            });
            this.studentTestId = e.data.studentTestId;
            this.testPaper = e.data;
            this.totalQuestionTypeNum = this.testPaper.questionTypes.length;
            // 题目类型
            this.questionTypes = this.testPaper.questionTypes;
            console.log(this.testPaper);
          } else {
            this.$.ui.toast(e.msg);
            setTimeout(() => {
              this.$router.back();
            }, 1000);
          }
        }
      );
    },
    // 更换题目类型
    changeQues(questionType) {
      this.currentPage = questionType;
      if (this.currentPage === this.totalQuestionTypeNum) {
        this.haveNext = false;
        this.havePre = true;
      } else if (this.currentPage === 1) {
        this.havePre = false;
        this.haveNext = true;
      } else {
        this.havePre = true;
        this.haveNext = true;
      }
    },
    prePage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.haveNext = true;
      }
      if (this.currentPage === 1) {
        this.havePre = false;
      }
    },
    nextPage() {
      if (this.currentPage === this.totalQuestionTypeNum - 1) {
        this.haveNext = false;
      }
      if (this.currentPage < this.totalQuestionTypeNum) {
        this.currentPage++;
        this.havePre = true;
      }
    },
    // 0分 问答题
    setZero(item) {
      console.log(item);
      item.studentScore = 0;
      this.currentScore = item.studentScore;
      this.curQuestionId = item.testQuestionId;
      this.scoreFlag = 'n';
    },
    // 满分 问答题
    set100(item1, item2) {
      this.curQuestionFullScore = item2.eachScore;
      item1.studentScore = item2.eachScore;
      this.currentScore = item1.studentScore;
      this.curQuestionId = item1.testQuestionId;
      this.scoreFlag = 'y';
    },
    // 正确
    right(item1, item2, questionidx, flagList) {
      this.curQuestionFullScore = item2.eachScore;
      item1.studentScore = item2.eachScore;
      this.currentScore = item1.studentScore;
      this.curQuestionId = item1.testQuestionId;
      this.scoreFlag = 'y';
      this.post(
        '/biz/teacher/qb/manuCheckQuestion',
        { studentScore: this.currentScore, testQuestionId: this.curQuestionId },
        e => {
          if (e.code == 200) {
            this.$.ui.toast('提交成功');
            flagList.splice(questionidx, 1, this.scoreFlag);
            // flagList[questionidx] = this.scoreFlag
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 错误
    wrong(item, questionidx, flagList) {
      console.log(item);
      item.studentScore = 0;
      this.currentScore = item.studentScore;
      this.curQuestionId = item.testQuestionId;
      this.scoreFlag = 'n';
      this.post(
        '/biz/teacher/qb/manuCheckQuestion',
        { studentScore: this.currentScore, testQuestionId: this.curQuestionId },
        e => {
          if (e.code == 200) {
            this.$.ui.toast('提交成功');
            flagList.splice(questionidx, 1, this.scoreFlag);
            // flagList[questionidx] = this.scoreFlag
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 打分回调
    onScore(item1, item2) {
      item1.studentScore.substring(0, 1) == '0'
        ? (item1.studentScore = '0')
        : (item1.studentScore = item1.studentScore);
      this.curQuestionFullScore = item2.eachScore;
      this.currentScore = item1.studentScore;
      this.curQuestionId = item1.testQuestionId;
      if (this.curQuestionFullScore == this.currentScore) {
        this.scoreFlag = 'y';
      } else {
        this.scoreFlag = 'n';
      }
    },
    // 提交每一题的分数
    submitScore(questionidx, flagList, studentScore) {
		if(!studentScore)studentScore=0;
      if (studentScore > this.curQuestionFullScore) {
        this.$.ui.toast(`此题分值为${this.curQuestionFullScore}分,打分分值不可大于本题分数！`);
        return;
      }
      this.post(
        '/biz/teacher/qb/manuCheckQuestion',
        { studentScore: this.currentScore, testQuestionId: this.curQuestionId },
        e => {
          if (e.code == 200) {
            this.$.ui.toast('提交成功');
            flagList.splice(questionidx, 1, this.scoreFlag);
            // flagList[questionidx] = this.scoreFlag
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    },
    // 结束对本学生的判卷
    finishCheck() {
      this.post('/biz/teacher/qb/manuCheckSubmit', { studentTestId: this.studentTestId }, e => {
        if (e.code == 200) {
          this.$.ui.toast('已结束本学生的判卷');
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 切换试卷
    changeTestPaper(type) {
      this.post(
        '/biz/teacher/qb/switchStudent',
        { currStudentId: this.studentId, examId: this.examId, switchDirection: type },
        e => {
          if (e.code == 200) {
            // 添加分数字段
            e.data.questionTypes.forEach(questionItem => {
              questionItem.eachScore = questionItem.totalScore / questionItem.questionNum;
              questionItem.questions.forEach(question => {
                question.studentScore = '';
              });
            });
            this.studentTestId = e.data.studentTestId;
            this.testPaper = e.data;
            this.totalQuestionTypeNum = this.testPaper.questionTypes.length;
            // 题目类型
            this.questionTypes = this.testPaper.questionTypes;
            console.log(this.testPaper);
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
    }
  },
  components: {}
};
</script>

<style lang="less"></style>
